import { Grid } from "@mui/material";
import { Fragment, useState } from "react";

import { Paper } from "../../../components";
import ShowAudienceSizeDialog from "./ShowAudienceSize";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function AdminCampaignStatus({ audienceSize, formikProps }) {
  const [showAudSize, setShowAudSize] = useState();

  return (
    <Fragment>
      {!!(audienceSize?.audience_params || audienceSize?.audience_size) &&
        formikProps.values.permissionSettings?.consumer_audience_view && (
          <Paper>
            <Grid item xs={12} textAlign={"center"} display={"flex"}>
              <ActiveTextEighteen style={{ color: colors.green }}>
                Your audience size is ready ! &nbsp;{" "}
              </ActiveTextEighteen>
              <ActiveTextEighteen
                color={colors.purple}
                style={{ cursor: "pointer" }}
                onClick={() => setShowAudSize(true)}
              >
                View
              </ActiveTextEighteen>
            </Grid>
          </Paper>
        )}

      {formikProps.values.campaignStatus === "CREATED" &&
        !formikProps.values?.isPendingSizing &&
        !(audienceSize?.audience_params || audienceSize?.audience_size) && (
          <Paper>
            <Grid item xs={12} textAlign={"center"} display={"flex"}>
              <ActiveTextEighteen style={{ color: colors.warning }}>
                Your campaign request has been raised and is expected to be
                ready within 24 to 72 hours.
              </ActiveTextEighteen>
            </Grid>
          </Paper>
        )}

      {formikProps.values.campaignStatus === "CREATED" &&
        formikProps.values?.isPendingSizing &&
        !(audienceSize?.audience_params || audienceSize?.audience_size) && (
          <Paper>
            <Grid item xs={12} textAlign={"center"} display={"flex"}>
              <ActiveTextEighteen style={{ color: colors.warning }}>
                The process is taking longer than expected. Please contact the
                admin.
              </ActiveTextEighteen>
            </Grid>
          </Paper>
        )}

      <ShowAudienceSizeDialog
        audienceSize={audienceSize}
        showAudSize={showAudSize}
        setShowAudSize={setShowAudSize}
      />
    </Fragment>
  );
}
